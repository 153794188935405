@import 'public/less/variables.less';

.graph {
  position: relative;
  display: inline-block;
  width: 100%;

  .graphLog {
    left: 575px;

    .loadAhead {
      cursor: pointer;
      animation: throb 1s ease alternate infinite;
    }
  }

  @keyframes throb {
    50% {
      r: 20;
    }

    100% {
      r: 18;
    }
  }

  .nodeContainer {
    position: absolute;
    left: 30px;
    top: 120px;
    width: 100%;

    .commit-container {
      position: absolute;
      top: -43px;
    }

    .rightSideContainer {
      position: absolute;
      display: flex;
      margin-left: (@log-width-small + 40px);
      top: -22px;
      white-space: nowrap;
      height: 40px;
    }

    .ref {
      display: inline-block;
      opacity: 0.6;
      cursor: move;
      margin: 7px 0 4px 0;
      outline: none;
      padding: 2px 5px 2px 5px;
      border: 3px solid transparent;
      border-radius: 10px;
      transition: border-color 0.5s;
      -webkit-transition: border-color 0.5s;

      &.dragging.focused {
        border-color: transparent;
      }

      &.focused {
        border-color: #ffffff;
      }

      &.current {
        font-weight: bold;
        opacity: 1;
        font-size: 20px;
        margin-top: 2px;
        margin-bottom: -2px;
      }

      &.remote {
        color: #5db4ff;
      }

      &.tag {
        color: #eef266;
      }
    }

    .graphAction {
      color: #ffffff;
      cursor: pointer;
      transition: all 0.5s ease 0.2s;
      transition-property: opacity;
      margin: 8px 2.5px 4px 2.5px;
      padding: 5px 10px;
      position: relative;
      border-radius: 3px;

      .dropmask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      &.dimmed {
        opacity: 0.5;
      }

      &.push {
        background: rgba(61, 139, 255, 0.9);
      }

      &.pull {
        background: rgba(38, 189, 189, 0.9);
      }

      &.reset {
        background: rgba(255, 129, 31, 0.9);
      }

      &.rebase {
        background: rgba(65, 222, 60, 0.9);
      }

      &.squash {
        background: rgba(100, 60, 222, 0.9);
      }

      &.move {
        background: rgba(0, 0, 0, 0.1);
      }

      &.merge {
        background: rgba(208, 135, 212, 0.9);
      }

      &.checkout {
        background: rgba(205, 219, 55, 0.9);
      }

      &.delete {
        background: rgba(214, 77, 56, 0.9);
      }

      &.cherry-pick {
        background: rgba(110, 156, 110, 0.9);
      }

      &.uncommit {
        background: rgba(158, 53, 20, 0.9);
      }

      &.revert {
        background: rgba(179, 135, 43, 0.9);
      }
    }

    .ref-icons {
      opacity: 1;
      padding-top: 2px;
      padding-bottom: 2px;
      margin-left: 5px;

      .showBranchingForm {
        background: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        margin: 0;
        margin-top: 9px;
        -webkit-text-stroke-width: 1px;
        color: rgba(255, 255, 255, 0.3);

        &:hover {
          color: rgba(255, 255, 255, 0.8);
        }
      }

      .showSearchForm {
        background: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        margin: 0;
        margin-top: 9px;
        -webkit-text-stroke-width: 1px;
        color: rgba(255, 255, 255, 0.3);

        &:hover {
          color: rgba(255, 255, 255, 0.8);
        }
      }

      .form-inline {
        display: inline-block;
      }

      input.name {
        width: 150px;
      }
    }
  }

  .graphFooter {
    height: 60px;
  }
}

.ui-autocomplete {
  min-width: 150px;

  .octicon {
    width: 18px;
  }
}
